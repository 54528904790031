export enum ChainId {
    OPTIMISM = 10,
    BASE = 8453,
    BASE_SEPOLIA = 84532,
    ARBITRUM = 42161,
    POLYGON = 137,
    LOCAL_HARDHAT = 31337,
}

export enum AssetId {
    ETH = 0,
    USDC = 2,
    USDT = 3,
    MATIC = 4,
    CBBTC = 5,
}

export enum ExcludedInputCurrency {
    DAI = 'DAI',
}

/**
 * NOTE: All keys in the AmountInputCurrency enum must be uppercase.
 * These keys are used as an index in the getCurrencyEnum function,
 * which normalizes input to uppercase.
 */
export enum AmountInputCurrency {
    ETH = 'ETH',
    USDC = 'USDC',
    CBBTC = 'cbBTC',
}

export const AMOUNT_INPUT_CURRENCY_ORDER = [
    AmountInputCurrency.USDC,
    AmountInputCurrency.ETH,
    AmountInputCurrency.CBBTC,
];

export const POLYGON_IDS = new Set([ChainId.POLYGON]);
const MAX_CAPACITY_USDC = 5_000_000; // 5m
const MAX_CAPACITY_ETH = 1339; // ~5m
const MAX_CAPACITY_CBBTC = 51; // ~5m
const MAX_CUSTOM_CAPACITY_USDC = 30_000_000; // 30m
const MAX_CUSTOM_CAPACITY_ETH = 7_500; // ~30m
const MAX_CUSTOM_CAPACITY_CBBTC = 300; // ~30m
const NOTIFY_CAPACITY_USDC = 500_000;
const NOTIFY_CAPACITY_ETH = 150;
const NOTIFY_CAPACITY_CBBTC = 5;

const COVER_30_DAYS_CAPACITY_USDC = 10_000;
const COVER_30_DAYS_CAPACITY_ETH = 3;
const COVER_30_DAYS_CAPACITY_CBBTC = 0.1;

export const COVER_INPUT_AMOUNTS = {
    [AmountInputCurrency.ETH]: {
        min: 0.001,
        max: MAX_CAPACITY_ETH,
        maxCustomDuration: MAX_CUSTOM_CAPACITY_ETH,
        notify: NOTIFY_CAPACITY_ETH,
        forceDaysAmount: COVER_30_DAYS_CAPACITY_ETH,
        decimals: 18,
    },
    [AmountInputCurrency.USDC]: {
        min: 1,
        max: MAX_CAPACITY_USDC,
        maxCustomDuration: MAX_CUSTOM_CAPACITY_USDC,
        notify: NOTIFY_CAPACITY_USDC,
        forceDaysAmount: COVER_30_DAYS_CAPACITY_USDC,
        decimals: 6,
    },
    [AmountInputCurrency.CBBTC]: {
        min: 0.000011,
        max: MAX_CAPACITY_CBBTC,
        maxCustomDuration: MAX_CUSTOM_CAPACITY_CBBTC,
        notify: NOTIFY_CAPACITY_CBBTC,
        forceDaysAmount: COVER_30_DAYS_CAPACITY_CBBTC,
        decimals: 8,
    },
};

export const coverAssets = {
    [AssetId.ETH]: { symbol: 'ETH', decimals: 18 },
    [AssetId.MATIC]: { symbol: 'MATIC', decimals: 18 },
    [AssetId.USDC]: { symbol: 'USDC', decimals: 6 },
    [AssetId.USDT]: { symbol: 'USDT', decimals: 6 },
    [AssetId.CBBTC]: { symbol: 'cbBTC', decimals: 8 },
};

export const isStablecoin = (assetId: AssetId) => {
    return assetId === AssetId.USDC || assetId === AssetId.USDT;
};
